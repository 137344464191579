import React from 'react'
import SectionTop from '../components/SectionTop'

const LoadingPage = () => {
    return (
        <div className='loading-page'>
            <SectionTop title="Loading ..." light/>
        </div>
    )
}

export default LoadingPage
