import React from 'react'
import Home from "./Home"
import Music from "./Music"
import Events from "./Events"
import Education from "./Education"
import Activism from "./Activism"
import About from "./About"
import Contact from "./Contact"
// import LoadingPage from './LoadingPage'

const Main = React.forwardRef(({content, language, getCreditReference}, ref) => {



    // if(content.length===0) return <LoadingPage />
    return (
        <div className="main-container" ref={ref}>
            <Home content={content.home} language={language} />
            <Music content={content.music} language={language} />
            <Events content={content.dates} language={language} />
            <Education content={content.education} language={language} getCreditReference={getCreditReference} />
            <Activism content={content.activism} language={language} getCreditReference={getCreditReference} />
            <About content={content.about} language={language} getCreditReference={getCreditReference} />
            <Contact content={content.contact} language={language} />
        </div>
    )
})

export default Main
