import React from 'react'
import EducationCard from '../components/EducationCard'
import SectionTop from '../components/SectionTop'

const Education = ({content, language, getCreditReference}) => {

    return (
        <div id="education" className="section section-light">
            <SectionTop title={content.title[language]} light />
            <div className="section-body">
                        <EducationCard 
                            leftImage={content.leftImage}
                            rightImage={content.rightImage}
                            iconList={content.iconList}
                            text={content.text[language]}
                            language={language}
                            getCreditReference={getCreditReference}
                            />       
            </div>
            
        </div>
    )
}

export default Education
