import React from 'react'
import CardWide from '../components/CardWide'
import SectionTop from '../components/SectionTop'
import './Activism.css'
import sanityClient from '../client'
import imageUrlBuilder from '@sanity/image-url'
import BlockContent from '@sanity/block-content-to-react'

const Activism = ({content, language, getCreditReference}) => {
    const builder = imageUrlBuilder(sanityClient)

    const urlFor = (source) => {
        if(source) return builder.image(source);
    }
    return (
        <div id="activism" className="section section-dark">
            <SectionTop title={content.title[language]} trans />
            <div className="section-body">
                <CardWide 
                    leftImage={content.leftImage}
                    rightImage={content.rightImage}
                    text={content.text[language]}
                    language={language}
                    getCreditReference={getCreditReference}
                />

                <div className='divider'></div>
                {/* <SectionTop title="Learn more" trans /> */}
                <div className='article-section'>
                    <div className='article'>
                        {content.videoTitle && <h2 className='h2-heading'>{content.videoTitle[language]}</h2>}
                        {content.video && <div className="video-frame">
                            <iframe 
                            className='video-medium'
                            src={`https://www.youtube-nocookie.com/embed/${content.video.split('?v=')[1]}`}  
                            title="YouTube video player" 
                            frameBorder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                            allowFullScreen></iframe>
                        </div>}
                        <div className='article-text'>
                        
                        <p>
                        {content.videoDescription && content.videoDescription[language]}</p></div>
                    </div>
                    <div className='article'>
                        {content.articleTitle && <h2 className='h2-heading'>{content.articleTitle[language]}</h2>}
                        {content.articleImage && <div className="video-frame">
                            <img 
                                src={urlFor(content.articleImage.image).width(370).height(210)}
                                width="370px"
                                height="210px"
                                alt={content.articleImage.image_description ? content.articleImage.image_description[language] : ""}
                            />
                            {content.articleImage.image_credits && <p className='image-author'>{getCreditReference(content.articleImage.image_credits._ref).imageCredits[language]}</p>}
                        </div>}
                        {content.articleText && <div className='article-text'>
                        <BlockContent blocks={content.articleText[language]} />
                        </div>}
                    </div>
                </div>
                {/* <div className='video-section'>
                    {content.video && <div className="video-frame">
                        <iframe 
                        className='video-medium'
                        src={`https://www.youtube-nocookie.com/embed/${content.video.split('?v=')[1]}`}  
                        title="YouTube video player" 
                        frameBorder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowFullScreen></iframe>
                    </div>}
                    {content.videoDescription && <div className='video-description'>
                        {content.videoTitle && <h2 className='h2-heading'>{content.videoTitle}</h2>}
                        <p>
                        {content.videoDescription}</p></div>}
                </div>                 */}
            </div>
            
        </div>
    )
}

export default Activism
