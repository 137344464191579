import React from 'react'
import fbIcon from '../icons/icon-fb.png'
import ytIcon from '../icons/icon-yt.png'
import igIcon from '../icons/icon-ig.png'
import spotifyIcon from '../icons/Spotify_icon.png'
import './Footer.css'

const Footer = ({ content, language }) => {
    return (
        <div className="footer">
            <div className="footer-content">
                <span className="footer-year">&copy;{new Date().getFullYear()}</span>
                <span className="footer-item">
                <a href="/impressum#privacy">{content?.privacy?.title[language]}</a>
                <a href="/impressum">{content?.impressum?.title[language]}</a>
                </span>
                
                <span className="footer-links">
                    <a href="https://www.facebook.com/zohar.l.aviv" target="_blank" rel="noreferrer noopener">
                        <img className="icon" src={fbIcon} alt="facebook icon"/></a>
                    <a href="https://www.instagram.com/zoharlioz/" target="_blank" rel="noreferrer noopener">
                        <img className="icon" src={igIcon} alt="instagram icon"/>
                    </a>
                    <a href="https://www.youtube.com/channel/UCnAxf_Z7Xk0kLCPBiKcDfeA" target="_blank" rel="noreferrer noopener">
                        <img className="icon" src={ytIcon} alt="youtube icon"/>
                    </a>
                    <a href="https://open.spotify.com/artist/7DYApq51LfAPTXeH9fAMds" target="_blank" rel="noreferrer noopener">
                        <img className="icon" src={spotifyIcon} alt="spotify icon"/>
                    </a>
                </span>
            </div>

        </div>
    )
}

export default Footer
