import React from 'react'

const SelectLan = ({language, handleLanchange}) => {
    return (
    <select onChange={handleLanchange} value={language} className='lan-select' >
        <option value="en">EN</option>
        <option value="de">DE</option>
        <option value="he">עב</option>
    </select>
    )
}

export default SelectLan
