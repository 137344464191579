import React, { useState } from 'react'
//import { NavLink } from 'react-router-dom'
import { Link } from 'react-scroll'
import { useLocation } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import logo from '../icons/ZoharLogoV2Dark.png'
import light from '../icons/Scheinwerferlicht.png'
import './NavBar.css'
import SelectLan from './SelectLan'


const NavBar = React.forwardRef(({language, changeLan, content}, ref) => {
    const [showDropdown, setShowDropdown] = useState(false)
    const location = useLocation()
    const navItems = ["home", "music", "dates", "education", "activism", "about", "contact"]

    // //sollte unnötig sein!?
    // useEffect(()=>{
    //     //console.log(content)
    // }, [language])


    const hideDropdownMenu = () => setShowDropdown(false)

    const toggleDropdownMenu = () => setShowDropdown(!showDropdown)

    const getTitleName = (section) => {
        if(!content) return ""
        if(section==="home") return "Zohar"
        const nameEntry = content[section]
        if(!nameEntry){
            return ""
        }
        //console.log(content)
        return nameEntry.title[language]
    }

    const handleLanchange = (e) => {
        changeLan(e.target.value)
        setShowDropdown(false)
    }

    if(location.pathname==="/privacy" || location.pathname==="/impressum"){
        return (
            <header>
            <div >
                <nav className="nav" >
                    <div className="nav-container" style={{alignItems: "center"}}>

                    <NavLink to="/" className="nav-item" ><img src={logo} className="nav-logo" alt='Zohar Home Logo'/></NavLink>
                    <div style={{margin: "auto", padding: "10px"}}>
                        <SelectLan language={language} handleLanchange={handleLanchange} />
                    </div>
                    </div>    
                    
                </nav>
            </div>
        </header>
        )
    }

    return (
        <header>
            <div >
                <nav className="nav" >
                    <div className="nav-container" ref={ref} >
                    {/* 
                    */}
                    {navItems.map((item, index)=>{
                        if(item==="home") return (<Link
                            key={`${item}${index}`} 
                            className="nav-item nav-title"
                            to="home"
                            spy={true}
                            smooth={true}
                            offset={-40}
                            duration={500}
                        >          
                            <img src={light} className='nav-logo' alt='Zohar Logo' />
                        </Link>)
                        else return (
                        <Link
                            key={`${item}${index}`} 
                            activeClass="active"
                            className="nav-item"
                            to={item}
                            spy={true}
                            smooth={true}
                            offset={-40}
                            duration={500}
                        >{getTitleName(item)}</Link>
                    )})}
                    <div className='nav-lan'>
                        <SelectLan language={language} handleLanchange={handleLanchange} />
                    </div>
                    <button className="dropbtn" onClick={toggleDropdownMenu} style={{transform: showDropdown ? "rotate(45deg)": "rotate(0deg)"}}>+</button>
                    <div className='nav-dropdown' >
                        
                        {showDropdown && 
                            <div id="myDropdown" className="dropdown-content">
                            {navItems.map((item, index)=>(
                                <Link key={index} 
                                        to={item}
                                        onClick={hideDropdownMenu}
                                        className='dropdown-link'
                                        activeClass="active"
                                        spy={true}
                                        smooth={true}
                                        offset={-40}
                                        duration={500}>{getTitleName(item)}</Link>
                            ))}
                            <div className='drop-nav-lan'>
                                <button className="nav-button" value="en" onClick={handleLanchange}>EN</button>
                                <span className="lan-nav-item">|</span>
                                <button className="nav-button" value="de" onClick={handleLanchange}>DE</button>
                                <span className="lan-nav-item">|</span>
                                <button className="nav-button" value="he" onClick={handleLanchange}>עב</button>
                            </div>
                            <div className='drop-nav-footer'>
                                <NavLink className='footer-link' to='/impressum'>{getTitleName('impressum')}</NavLink>
                                <NavLink className='footer-link' to='/impressum#privacy'>{getTitleName('privacy')}</NavLink>
                            </div>
                            </div>
                        }
                        
                    </div>
                    
                        </div>    
                    
                </nav>
            </div>
        </header>
    )
})

export default NavBar
