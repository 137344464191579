import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import LoadingPage from './LoadingPage'
//import { useNavigate } from 'react-router-dom'

const Impressum = ({imprint_content, privacy_content, language}) => {
    //const navigate = useNavigate()
        
    // const goBack = () => {
    //     navigate(-1)
    // }

    if(!imprint_content) return <LoadingPage />
    return (
        <div className="section section-light section-extra imprint-section">
            <div className="section-top">
                <h2>{imprint_content.title[language]}</h2>
            </div>
            <div className="section-body">
                <BlockContent blocks={imprint_content.adresse} /> 
                {/* <div style={{width: "100%", display: "flex"}}>
                <div className='nav-item' onClick={goBack}>← Back</div>
                </div> */}
                
            </div>
            <div className="section-top" id='privacy'>
                <h2>{privacy_content.title[language]}</h2>
            </div>
            <div className="section-body">
                <BlockContent blocks={privacy_content.text[language]} />
                {/* <div style={{width: "100%", display: "flex"}}>
                    <div className='nav-item' onClick={goBack}>Back</div>
                </div> */}
            </div>
            
        </div>
    )
}

export default Impressum
