import React from 'react'
import sanityClient from '../client'
import imageUrlBuilder from '@sanity/image-url'
import Icon from '@mdi/react'
import {
    mdiPaletteSwatch,
    mdiSchool,
    mdiMusicNoteEighth
} from '@mdi/js'
import './EducationCard.css'

const EducationCard = ({ text, leftImage, rightImage, iconList, language, getCreditReference }) => {

    const builder = imageUrlBuilder(sanityClient)
    const iconNames = {
        music: {icon: mdiMusicNoteEighth},
        education: {icon: mdiSchool},
        palette: {icon: mdiPaletteSwatch}
    }

    const urlFor = (source) => {
        if(source) return builder.image(source);
    }
    return (

        <div className="card-container">
            <div className="column-one">
                <div className="column-one-image">
                <img 
                    src={urlFor(leftImage.image).width(370).height(250)} 
                    alt={leftImage.image_description ? leftImage.image_description[language] : ""} 
                />
                {leftImage.image_credits && <p className='image-author'>{getCreditReference(leftImage.image_credits._ref).imageCredits[language]}</p>}
                </div>
                {iconList && (
                    iconList.map((item, index)=>(
                        <div key={`IconList${index}`} className='card-icons'>
                        <Icon path={iconNames[item.iconName].icon} size={1.2} />
                        <p >{item.iconText[language]}</p>
                        </div>
                    ))
                )}
            </div>
            <div className="column-two">
                <div className="column-two-image">
                <img 
                    src={urlFor(rightImage.image).width(370).height(250)} 
                    alt={rightImage.image_description ? rightImage.image_description : ""} 
                />
                {rightImage.image_credits && <p className='image-author'>{getCreditReference(rightImage.image_credits._ref).imageCredits[language]}</p>}
                </div>
                {text}
            </div>
        </div>
    )
}

export default EducationCard
