import React from 'react'
import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import Main from "./pages/Main"
//import Privacy from "./pages/Privacy"
import Impressum from "./pages/Impressum"
import Footer from './components/Footer';
import { useState, useEffect, useLayoutEffect } from 'react';
import sanityClient from './client'
import NavBar from './components/NavBar';
import NavLight from './components/NavLight';
import NavBackground from './components/NavBackground';
import { loadReCaptcha } from 'react-recaptcha-google'
import LoadingPage from './pages/LoadingPage';

const contentTypes = [
  "home", "music", "dates", "education", "activism", "about", "contact", "privacy", "impressum"
]

function App() {
  const [language, setLanguage] = useState("english")
  const [content, setContent] = useState(null)

  const sectionRef = React.createRef()
  const navRef = React.createRef()

  const changeLan = (lan)=>{
    // if(lan==="de") document.documentElement.lang = "de"
    // else if(lan==="he") document.documentElement.lang = "he"
    // else document.documentElement.lang = "en"
    document.documentElement.lang = lan
    setLanguage(lan)
  }

  useLayoutEffect(()=>{
    if(navigator.language.includes("de")) changeLan("de")
    else if(navigator.language.includes("he")) changeLan("he")
    else changeLan("en")
    loadReCaptcha()
  }, [])

  useEffect(()=>{
      if(language==="") return
      sanityClient
          .fetch(`*[_id in $ids || _type == "credits"]`, {ids: contentTypes})
          .then((data)=>{
            const dataObject = data.reduce((a, v) => ({...a, [v._id]: v}), {})
            // console.log(dataObject)
            // save data to state
            setContent({...dataObject})
            // setTest(dataObject)
            })
          .catch(console.error)
  }, [language])

  const getCreditReference = (id) => {
    return content[id]
  }

  if(!content) return <LoadingPage />

  return (
    <div dir={language === "he" ? "rtl" : "ltr"}>
    <BrowserRouter>
    <NavBackground/>
    <NavBar language={language} changeLan={changeLan} content={content} ref={navRef} />
    <NavLight sectionRef={sectionRef} navRef={navRef}  language={language}/>
      <Routes>
        <Route exact path='/' element={<Main content={content} ref={sectionRef} language={language} getCreditReference={getCreditReference} />}>
        </Route>
        {/* <Route element={<Privacy content={content.privacy} />} path='/privacy'/> */}
        <Route element={<Impressum imprint_content={content.impressum} privacy_content={content.privacy} language={language} />} path='/impressum'/>  
        <Route element={<Navigate to="/"/>} path='*'/>    
      </Routes>
    <Footer content={content} language={language} />
    </BrowserRouter>
    </div>
  );
}

export default App;
