import React, { useState, useEffect } from 'react'
import { useWindowScroll } from 'react-use'
import './NavLight.css'

const NavLight = ({ sectionRef, navRef, language }) => {
    const [scrolled, setScrolled] = useState(0)
    const { y } = useWindowScroll()
    const totalWidth = document.documentElement.clientWidth>992 ? 830 : document.documentElement.clientWidth-80

    useEffect(()=>{
        const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        if(sectionRef.current && navRef.current && document.documentElement.clientWidth>992){
            let currentSectionLocation = 0
            let currentNavLocation = 0
            let navGap = navRef.current.offsetWidth
            for(let i=0; i<navRef.current.childNodes.length; i++) navGap-= navRef.current.childNodes[i].offsetWidth
            // zwei bei der Länge abziehen, da auch nicht sichtbare child nodes gezählt werden
            navGap /= (navRef.current.childNodes.length-2)
            for(let i=0; i<sectionRef.current.childNodes.length; i++){
                const currentSectionNode = sectionRef.current.childNodes[i]
                const currentNavNode = navRef.current.childNodes[i]
                const sectionHeight = currentSectionNode.offsetHeight
                const navWidth = currentNavNode.offsetWidth
                if(y<currentSectionLocation+sectionHeight){
                    setScrolled((((y-currentSectionLocation)/sectionHeight)*(navWidth+navGap))+currentNavLocation)
                    break
                }
                currentSectionLocation += sectionHeight
                currentNavLocation += (navWidth+navGap)
            }
        }else{
            setScrolled((y/height)*totalWidth)
        }      
    }, [y, navRef, sectionRef, totalWidth])

    return (
        <div className='navlight-container'>
            <div className='navlight-center'>
                <div className='navlight' style={{
                    marginRight: language === "he" ? scrolled : "auto", 
                    marginLeft: language === "he" ? "auto" : scrolled}}
                    ></div>
            </div>  
        </div>
    )
}

export default NavLight
