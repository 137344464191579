import React from 'react'



const TableRowDate = ({event, language, showDate=true}) => {

    const getMonth = (date) => {
        const d = new Date(date)
        
        const month = d.toLocaleString(language, { month: 'short' });
        return month
    }
    
    const getDay = (date) => {
        const d = new Date(date)
        const day = d.toLocaleString(language, {day: "2-digit"})
        return day
    }
    
  return (
    <tr className={`${new Date(event.date)>=Date.now() ? "future-date" : "past-date"}`} style={{display: showDate ? 'inherit' : 'none'}}>
        <td className='event-date'>
            <span className='month'>{getMonth(event.date)}</span>
            <span className='day'>{getDay(event.date)}</span>
        </td>
        <td className='event-text'>
            <span className='event-place'>
            {event.link ? 
                <a 
                    href={event.link} 
                    className="external-link" 
                    target="_blank" 
                    rel="noreferrer noopener">
                        {event.place}
                </a> 
                : 
            event.place}
            </span>
            <br/>
            {event.description && 
                <span className='event-description'>
                    {event.description[language] ?? ""}
                </span>
            }
            
        </td>
    </tr>
  )
}

export default TableRowDate