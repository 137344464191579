import React from 'react'
import sanityClient from '../client'
import imageUrlBuilder from '@sanity/image-url'
import './CardWide.css'

const CardWide = ({text, leftImage, rightImage, getCreditReference, language}) => {

    const builder = imageUrlBuilder(sanityClient)


    const urlFor = (source) => {
        if(source) return builder.image(source);
    }
    return (
        <div className="card-wide">
            <div className='card-image-one'>
                <img src={urlFor(leftImage.image).width(370).height(250)} alt={leftImage.image_description ? leftImage.image_description[language] : ""} />
                {leftImage.image_credits && <p className='image-author'>{getCreditReference(leftImage.image_credits._ref).imageCredits[language]}</p>}
            </div>
            <div className='card-image-two'>
                <img src={urlFor(rightImage.image).width(370).height(250)} alt={rightImage.image_description ? rightImage.image_description[language] : ""} />
                {rightImage.image_credits && <p className='image-author'>{getCreditReference(rightImage.image_credits._ref).imageCredits[language]}</p>}
            </div>

            <div className="card-column" >

                    {text}
                
            </div>
        </div>
    )
}

export default CardWide
