import React, { useState } from 'react'
import SectionTop from '../components/SectionTop'
import TableRowDate from '../components/TableRowDate'

const Events = ({content, language}) => {
    const [showAllEvents, setShowAllEvents] = useState(false)

    let currentYear = new Date().getFullYear()
    //console.log(typeof currentYear)
    // const getDate = (date) => {
    //     const d = new Date(date)
    //     if(language==='de'){
    //         //const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}
    //         return d.toLocaleDateString('de-DE')
    //     }
    //     return d.toDateString()
    // }

    // const getMonth = (date) => {
    //     const d = new Date(date)
        
    //     const month = d.toLocaleString(language, { month: 'short' });
    //     return month
    // }

    // const getDay = (date) => {
    //     const d = new Date(date)
    //     const day = d.toLocaleString(language, {day: "2-digit"})
    //     return day
    // }

    // const getYear = (date) => {
    //     const d = new Date(date)
    //     const year = d.toLocaleString('de-DE', {year: "numeric"})
    //     return year
    // }

    const comparator = (a, b) => {
        var c = new Date(a.date);
        var d = new Date(b.date);
        return d-c;
      };

    return (
        <section id="dates" className="section section-dark">
            <SectionTop title={content.title[language]} />
            <div className="section-body section-block">
                <div className="table-container">
                <table className='event-table'>
                    <tbody>
                        {content.list.sort(comparator).map((event, index)=>{
                            if(new Date(event.date).getFullYear()<currentYear && (index<content.maxNumberOfEvents || showAllEvents)){
                                currentYear-=1
                                return (
                                    <React.Fragment key={index} >
                                        <tr className='event-past-year'><td colSpan={2}>{currentYear}</td></tr>
                                        <TableRowDate 
                                            event={event} 
                                            language={language}
                                            showDate={index<content.maxNumberOfEvents || showAllEvents}
                                        />
                                    </React.Fragment>
                                )
                            }
                        
                        return(
                            <TableRowDate 
                                key={index} 
                                event={event} 
                                language={language}
                                showDate={index<content.maxNumberOfEvents || showAllEvents}
                            />
                        )    
                    })}
                    </tbody>
                </table>
                </div>
                <div className='show-events-container'>
                    <span 
                        className='show-events-button'
                        onClick={()=>setShowAllEvents(!showAllEvents)}
                    >
                            {showAllEvents ? content.hideEvents[language] : content.showAllEvents[language]}
                    </span>
                </div>
            </div>
            
        </section>
    )
}

export default Events
