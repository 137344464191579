import React from 'react'

const NavBackground = () => {
    return (
        <div className='nav-background'>
            
        </div>
    )
}

export default NavBackground
