import React from 'react'
import sanityClient from '../client'
import imageUrlBuilder from '@sanity/image-url'
import fbIcon from '../icons/icon-fb.png'
import ytIcon from '../icons/icon-yt.png'
import igIcon from '../icons/icon-ig.png'
import spotifyIcon from '../icons/Spotify_icon.png'
import './Home.css'

const Home = ({ content, language }) => {

    const builder = imageUrlBuilder(sanityClient)

    const urlFor = (source) => {
        if(source) return builder.image(source);
    }

    const section_styles = {
        backgroundImage: `url(${urlFor(content.mainImage.image).width(2000)})`,
    }

    return (
        <section id="home" className="section home" style={section_styles}>
            <header style={{transform: "scale(0)", position: "absolute"}}><h1>Zohar Lioz Aviv</h1></header>
            <div className="home-links">
                    <a className='facebook-link' href="https://www.facebook.com/zohar.l.aviv" target="_blank" rel="noreferrer noopener">
                        <img className="icon" src={fbIcon} alt="facebook icon"/></a>
                    <a className='instagram-link' href="https://www.instagram.com/zoharlioz/" target="_blank" rel="noreferrer noopener">
                        <img className="icon" src={igIcon} alt="instagram icon"/>
                    </a>
                    <a className='youtube-link' href="https://www.youtube.com/channel/UCnAxf_Z7Xk0kLCPBiKcDfeA" target="_blank" rel="noreferrer noopener">
                        <img className="icon" src={ytIcon} alt="youtube icon"/>
                    </a>
                    <a className='spotify-link' href="https://open.spotify.com/artist/7DYApq51LfAPTXeH9fAMds" target="_blank" rel="noreferrer noopener">
                        <img className="icon" src={spotifyIcon} alt="spotify icon"/>
                    </a>
                </div>
            <div className="section-body home-body">
                
                <p className="home-text"><mark className="home-text-mark">{content.text[language]}</mark></p>
            </div>
            {content.mainImage.image_credits && <p className='image-author-home'>{content.mainImage.image_credits.valueOf()[language]}</p>}
        </section>
    )
}

export default Home
