import React, { useState, useEffect, useRef } from 'react'
import emailjs from '@emailjs/browser'
import SectionTop from '../components/SectionTop'
import BlockContent from '@sanity/block-content-to-react'
import './Contact.css'
import { ReCaptcha } from 'react-recaptcha-google'

const Contact = ({content, language}) => {
    const [userMail, setUserMail] = useState("")
    const [message, setMessage] = useState("")
    const [checked, setChecked] = useState(false)
    const [loading, setLoading] = useState(false)
    const [sent, setSent] = useState(false)
    const [error, setError] = useState(false)
    const [token, setToken] = useState(null)

    // const service_id = process.env.REACT_APP_EMAILJS_SERVICE_ID
    // const template_id = process.env.REACT_APP_EMAILJS_TEMPLATE_ID
    // const user_id = process.env.REACT_APP_EMAILJS_USER_ID

    const service_id = "service_8xigdox"
    const template_id = "template_isxx0he"
    const user_id = "user_r6Rzb5Z10OKNLnoHexOcn"

    //const isReady = () => typeof window !== 'undefined' && typeof window.grecaptcha !== 'undefined';

    const reCaptchaRef = useRef(null)

    const onLoadRecaptcha = () => {
        if(reCaptchaRef.current){
            reCaptchaRef.current.reset()
        }
    }

    useEffect(()=>{
        if(reCaptchaRef.current) reCaptchaRef.current.reset()
        //return () => reCaptchaRef.current = null
    }, [])

    const changeUserMail = (e) => setUserMail(e.target.value)
    const changeMessage = (e) => setMessage(e.target.value)
    const changeChecked = (e) => {
        setChecked(!checked)}

    const handleSubmit = async (e) => {
        e.preventDefault()
        if(userMail === "" || message === "" || !checked || !token) return
        setLoading(true)
        emailjs.send(service_id, 
                    template_id, 
                    {from_mail: userMail, message: message, 'g-recaptcha-response': token}, 
                    user_id)
            .then(result=>{
                setLoading(false)
                setSent(true)
                reCaptchaRef.current.reset()
                setToken(null)
            }, error =>{
                setLoading(false)
                setError(true)
                reCaptchaRef.current.reset()
                setToken(null)
            }).catch(error=>setError(true))
    }



    const handleCallback = (token) => {
        setToken(token)
    }

    return (
        <div id="contact" className="section section-dark">
            <SectionTop title={content.title[language]}/>
            <div className="section-body">
                <div className='contact-text row'>
                    <BlockContent blocks={content.text[language]} />
                </div>
                <form className='form-container' onSubmit={handleSubmit}>
                <div className="form-input row">
                    <div className="col-15">
                        <label htmlFor="mailInput">{content.mailLabel[language]}</label>
                    </div>
                    <div className="col-70">
                        <input type="email" required id="mailInput" placeholder={content.mailDefault[language]} value={userMail} onChange={changeUserMail}/>
                    </div>
                </div>
                <div className="form-input row">
                    <div className="col-15">
                        <label htmlFor="textInput">{content.textLabel[language]}</label>
                    </div>
                    <div className="col-70">
                        <textarea required name="" id="textInput" rows="10" placeholder={content.textDefault[language]} value={message} onChange={changeMessage}></textarea>
                    </div>
                </div>
                <div className="form-input row checkbox-row">
                    <div className="col-15"></div>
                    <div className="col-70">
                        <input type="checkbox" value={checked} checked={checked} id="privacyCheck" required onChange={changeChecked}/>
                        <label htmlFor="privacyCheck"><BlockContent blocks={content.privacyText[language]} className='privacy-checkbox-label'/></label>
                    </div>   
                </div>
                <div className='row form-input'>
                    <div className='col-15'></div>
                    <div className='col-70'>
                        {checked && <ReCaptcha 
                            ref={reCaptchaRef}
                            size="normal"
                            render="explicit"
                            sitekey="6LfyURgeAAAAAOM3IWwByt9AiohSaqOANGnZQoAT"
                            onloadCallback={onLoadRecaptcha}
                            verifyCallback={handleCallback}
                            
                        />}
                    </div>
                    </div>
                <div className='row form-input'>
                    <div className='col-15'></div>
                    <div className='col-70'>
                        <button 
                            className="form-button" 
                            disabled={sent || error}
                            >
                            {loading ? <div className='loader'></div> : sent ? "✓" : content.button[language]}
                        </button>
                        {sent && <div className='form-success'>{content.success[language]}</div>}
                        {error && <div className='form-error'>Error :(</div>}
                    </div>
                </div>                
                </form>
            </div>
        </div>
    )
}

export default Contact
