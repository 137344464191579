import React, { useState, useEffect, useRef } from 'react'
import './SectionTop.css'
const SectionTop = ({title, light=false, trans=false}) => {
    const lightRef = useRef(null)
    const [isVisible, setIsVisible] = useState(false)
    
    useEffect(()=>{
        const ref = lightRef.current ? lightRef.current : null
        const callback = (entries) => {
            if(isVisible) return
            const [entry] = entries
            setIsVisible(entry.isIntersecting)
            entry.target.childNodes[0].childNodes[0].classList.toggle("light1-active", entry.isIntersecting)
            entry.target.childNodes[0].childNodes[0].classList.toggle("light1-show", entry.isIntersecting)
            if(trans) entry.target.childNodes[0].childNodes[1].classList.toggle("light-trans-active", entry.isIntersecting)
            else entry.target.childNodes[0].childNodes[1].classList.toggle("light2-active", entry.isIntersecting)
            entry.target.childNodes[0].childNodes[2].classList.toggle("light3-active", entry.isIntersecting)
        }
        const observer = new IntersectionObserver(callback, {threshold: 1.0})
        if(lightRef.current && !isVisible) observer.observe(lightRef.current)
        else if(isVisible) observer.unobserve(ref)
        return ()=>{
            observer.unobserve(ref)
        }
    }, [lightRef, isVisible, trans])

    return (
        <header className="section-top" ref={lightRef}>
                <div className="light-container">
                    <div className={light ? "light-1 light-bg-light" : "light-1 light-bg-dark"}></div>
                    <div className={light ? "light-2 light-bg-light": trans ? "light-bg-dark light-trans" : "light-2 light-bg-dark"}></div>
                    <div className={light ? "light-3 light-bg-light": "light-3 light-bg-dark"}></div>
                </div>
                <h1 className="title">{title}</h1>
        </header>
    )
}

export default SectionTop
