import React from 'react'
import SectionTop from '../components/SectionTop'

const Music = ({content, language}) => {
    return (
        <section id="music" className="section section-light">
            <SectionTop title={content.title[language]} light />
            <div className="section-body">
                {content.videos.map((video, index)=>(
                    <div key={"video_" + index} className="video-frame video-margin">
                        <iframe 
                        className='video-small'
                        src={`https://www.youtube-nocookie.com/embed/${video.split('?v=')[1]}`}  
                        title="YouTube video player" 
                        frameBorder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowFullScreen></iframe>
                    </div>
                    
                ))}
                {/* <div className="video-frame">
                    <iframe title="fairytales" style={{border: 0, width: "322px", height: "414px"}} src="https://bandcamp.com/EmbeddedPlayer/track=2241766115/size=large/bgcol=ffffff/linkcol=333333/tracklist=false/transparent=true/" seamless><a href="https://zoharliozaviv.bandcamp.com/track/fairytales">Fairytales by Zohar Lioz-Aviv</a></iframe>
                </div>
                <div className="video-frame">
                    <iframe title="everything i need" style={{border: 0, width: "322px", height: "414px"}} src="https://bandcamp.com/EmbeddedPlayer/track=1695987094/size=large/bgcol=ffffff/linkcol=333333/tracklist=false/transparent=true/" seamless><a href="https://zoharliozaviv.bandcamp.com/track/everything-i-need">Everything I need by Zohar Lioz-Aviv</a></iframe>
                </div> */}
                            </div>

        </section>
    )   
}

export default Music
