import React from 'react'
import sanityClient from '../client'
import imageUrlBuilder from '@sanity/image-url'
import './About.css'
import SectionTop from '../components/SectionTop'

const About = ({content, language, getCreditReference}) => {
    const builder = imageUrlBuilder(sanityClient)

    // React.useEffect(()=>{
    //     console.log(content)
    // }, [content])

    const urlFor = (source) => {
        if(source) return builder.image(source);
    }
    return (
        <div id="about" className="section section-light">
            <SectionTop title={content.title[language]} light />
            <div className="section-body about-body">
                <div className="about-image-container">
                    <div className="about-light"></div>
                    <img className="about-image" src={urlFor(content.image.image)} alt={content.image.image_desciption ? content.image_desciption[language] : ""} />
                    {content.image.image_credits && <p className='image-author-about'>{getCreditReference(content.image.image_credits._ref).imageCredits[language]}</p>}
                </div>
                <div className='about-text-container'>
                    <p className="about-text">{content.text[language]}</p>
                </div>
                
            </div>
        </div>
    )
}

export default About
